import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/common/Alert';
import { passPropsToChildren } from 'services/components';
import { LocalizationContext } from 'services/localization';
import LoadingSpinner from 'components/common/LoadingSpinner';
import useGift from './hooks/useGift';
import useGiftDetails from './hooks/useGiftDetails';
import Footer from 'components/common/Footer';
import EntryFooter from 'components/Home/components/Footer';
import { useGiftCompliance } from './hooks/useGiftCompliance';
import { useGiftReview } from './hooks/useGiftReview';
import { useGiftSuccess } from './hooks/useGiftSuccess';
import './styles/gift.scss';

export default function Gift({ locale, location, settings, children, params, bodyRef, loadLocale }) {
  const localized = useContext(LocalizationContext);
  const { flatProcessFee, percentageProcessFee } = settings || {};

  const {
    isDataLoading,
    isGiftEntryPage,
    isReviewPage,
    alertProps,
    beneficiary,
    setAlertProps,
    setIsDataLoading,
    setBeneficiary,
    message,
    severity,
    onGiftNowClick
  } = useGift({ location, params, bodyRef, loadLocale });

  const {
    relationship,
    relationshipError,
    updateRelationship,
    onComplianceNext,
    validateRelationshipForm
  } = useGiftCompliance();

  const {
    isSubmitLoading,
    selectedGiftAmount,
    checkoutMessage,
    giftOptions,
    isAmountEmptyMessage,
    amountError,
    messageError,
    totalProcessingFee,
    selectedGiftOption,
    updateMessage,
    updateGiftAmount,
    setSelectedGiftAmount,
    onDetailsNext,
    updateGiftOption,
    validateDetailsForm
  } = useGiftDetails({ localized, setAlertProps, flatProcessFee, percentageProcessFee });

  const {
    submitCheckout,
    isPaymentLoading
  } = useGiftReview({
    selectedGiftAmount,
    checkoutMessage,
    relationship,
    validateDetailsForm,
    validateRelationshipForm,
    setAlertProps,
    locale
  });

  const {
    onSuccessDoneClick
  } = useGiftSuccess();

  const childrenWithProps = passPropsToChildren(children, {
    locale,
    localized,
    params,
    alertProps,
    beneficiary,
    relationship,
    relationshipError,
    setAlertProps,
    setBeneficiary,
    setIsDataLoading,
    isDataLoading,
    isPaymentLoading,
    onGiftNowClick,
    onComplianceNext,
    updateRelationship,
    selectedGiftAmount,
    setSelectedGiftAmount,
    totalProcessingFee,
    checkoutMessage,
    giftOptions,
    isSubmitLoading,
    isAmountEmptyMessage,
    amountError,
    messageError,
    updateMessage,
    updateGiftAmount,
    submitCheckout,
    onDetailsNext,
    message,
    severity,
    updateGiftOption,
    selectedGiftOption,
    validateRelationshipForm,
    validateDetailsForm,
    onSuccessDoneClick
  });

  return (
    <div className='gift'>
      {message && (
        <Alert className='gift__error' severity={severity}>
          {localized[message]}
        </Alert>
      )}
       {isDataLoading
        ? <LoadingSpinner />
        : <div className={`
            gift__content-wrapper 
            ${isReviewPage ? 'gift__content-wrapper--review' : ''}
          `}>
            {childrenWithProps}
          </div>
       }
      {!isDataLoading && !isReviewPage ? (
          !isGiftEntryPage ? <Footer /> : <EntryFooter/>
        )
      : <></>}
    </div>
  );
}

Gift.propTypes = {
  locale: PropTypes.string,
  location: PropTypes.object,
  settings: PropTypes.object,
  children: PropTypes.object,
  params: PropTypes.object,
  bodyRef: PropTypes.object,
  loadLocale: PropTypes.func
};
