import { useEffect, useState } from "react";
import { browserHistory } from "react-router";
import { useCancelPromise } from "services/hooks";
import { getValidateGiftCode, getPublicBeneByGiftCode, setAuthToken } from "services/webApi";
import { processToken } from "services/token";
import { logComponentEvent } from "services/logging/componentEvent";
import { getLocaleFromBrowser } from "services/locale";

export default function useGift({ location, params, bodyRef, loadLocale }) {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [beneficiary, setBeneficiary] = useState({});
  const [alertProps, setAlertProps] = useState({});
  const { id: giftCode } = params || {};
  const { message, severity } = alertProps || {};

  const savedToken = sessionStorage.getItem('token');
  const isReviewPage = /^(\/gift\/review)$/.test(location.pathname);
  const isGiftEntryPage = /^(\/gift)$/.test(location.pathname);
  const isGiftSuccessPage = /^(\/gift\/success)/.test(location.pathname);
  const isGiftErrorPage = /^(\/gift\/error)/.test(location.pathname);
  const cancelPromise = useCancelPromise();

  // Returns token; use cached token only if no giftCode in param is passed
  const validateGiftCode = (giftCode) => {
    if (!giftCode) return savedToken ? Promise.resolve(savedToken) : Promise.reject();

    return getValidateGiftCode(giftCode)
      .then(({ data: { token: newToken } }) => {
        if (!cancelPromise.current) {
          return newToken;
        }
      });
  };

  // Load in public beneficiary information by gift code
  useEffect(() => {
    loadLocale(getLocaleFromBrowser());

    if (isGiftSuccessPage || isGiftErrorPage) {
      return setIsDataLoading(false);
    }

    setIsDataLoading(true);
    setAuthToken(sessionStorage.getItem('token'));
    validateGiftCode(giftCode)
      .then((token) => {
        if (!cancelPromise.current) {
          processToken(sessionStorage, token);
          return getPublicBeneByGiftCode();
        }
      })
      .then(({ data: { firstName, profilePictureUrl, lastInitial } }) => {
        if (!cancelPromise.current) {
          setBeneficiary({ firstName, profilePictureUrl, lastInitial });
          return browserHistory.push('/gift');
        }
      })
      .catch(() => {
        if (!cancelPromise.current) {
          return browserHistory.push('/gift/error?reason=expire');
        }
      })
      .finally(() => {
        if (!cancelPromise.current) {
          setIsDataLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    const backListener = browserHistory.listen((location) => {
      if (location.action === 'PUSH' || location.action === 'POP') {
        if (bodyRef) {
          bodyRef.current.scrollTop = 0;
        }
        setAlertProps(null);
      }
    });

    return () => backListener();
  }, []);

  const onGiftNowClick = () => {
    logComponentEvent('GiftingAbout', 'ContinueClicked', { data: { button: 'Continue' } });
    browserHistory.push('/gift/details');
  };

  return {
    isDataLoading,
    isGiftEntryPage,
    isReviewPage,
    alertProps,
    beneficiary,
    setAlertProps,
    setIsDataLoading,
    setBeneficiary,
    message,
    severity,
    onGiftNowClick,
    bodyRef
  };
};
