import { connect } from 'react-redux';
import { loadLocale } from 'reducers/locale';
import Gift from './Gift';

const mapDispatchToProps = {
  loadLocale
};

const mapStateToProps = ({ locale, settings }) => {
  return { locale, settings };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gift);
