import { useState } from "react";
import { postGiftPaymentLink } from "services/webApi";
import { AMOUNT_DECIMAL_OFFSET } from "../services/constants";
import { useCancelPromise } from "services/hooks";
import { logComponentEvent } from "services/logging/componentEvent";

export const useGiftReview = ({
  selectedGiftAmount,
  checkoutMessage,
  relationship,
  setAlertProps,
  validateRelationshipForm,
  validateDetailsForm,
  locale
}) => {
  const [isPaymentLoading, setIsPaymentLoading] = useState();
  const cancelPromise = useCancelPromise();

  // Calculates Stripe unit amount format for pricing
  const calculateStripeGiftAmount = (value) => Math.round(value * AMOUNT_DECIMAL_OFFSET);

  // Checkout after validation
  const submitCheckout = () => {
    logComponentEvent(
      'GiftingReview',
      'Pay',
      { data: { button: 'Pay and send gift' } }
    );

    const { isAmountValid, isMessageValid } = validateDetailsForm(selectedGiftAmount, checkoutMessage);
    const { isRelationshipValid } = validateRelationshipForm(relationship);

    if (!isAmountValid || !isMessageValid || !isRelationshipValid) {
      return setAlertProps({
        severity: 'error',
        message: 'giftDetails.error.allFieldsRequired'
      });
    }

    setAlertProps({});
    setIsPaymentLoading(true);
    postGiftPaymentLink({
      price: calculateStripeGiftAmount(selectedGiftAmount),
      relationship,
      message: checkoutMessage,
      locale
    })
      .then((response) => {
        if (!cancelPromise.current) {
          const { stripeUrl } = response?.data || {};

          if (stripeUrl) {
            window.location.href = stripeUrl;
          } else {
           setAlertProps({
              severity: 'error',
              message: 'networkError.title'
            });
          }
        }
      })
      .catch(() => {
        if (!cancelPromise.current) {
          setAlertProps({
            severity: 'error',
            message: 'networkError.title'
          });
        }
      })
      .finally(() => {
        if (!cancelPromise.current) {
          setIsPaymentLoading(false);
        }
      });
  };

  return {
    submitCheckout,
    isPaymentLoading
  };
};
