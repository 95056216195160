/* eslint-disable quotes */
/* eslint-disable max-len */
export default {
  'embark': 'Embark',
  'embarkStudentCorp': 'Embark étudiant corp.',
  'home.footerCopyrightSymbol': '©',
  'home.footerAllRightsReserved': 'Tous droits réservés.',
  'home.footerTrademarkText': "Embark est une marque de commerce d'Embark Student Corp.",
  'outage.title': 'Nous serons bientôt de retour en ligne.',
  'outage.message': 'Notre site est actuellement fermé pour cause de maintenance. Des mises à niveau passionnantes arrivent !',
  'register': "S'inscrire",
  'continue': "Continuer",
  'next': 'Suivante',
  'send': 'Envoyer',
  'retry': 'Refaire',
  'done': "Fait",
  'copy': 'Copie',
  'copied': 'Copié !',
  'cancel': "Annuler",
  'update': "Mise à jour",
  'skip': "Sauter",
  'and': "et",
  'note': "Noter:",
  'his': 'son',
  'her': 'son',
  'their': 'leur',
  'on': 'sur',
  'is': 'est',
  'disagree': "être en désaccord",
  'decline': "Refuser",
  'name': 'Nom',
  'change': 'Modifier',
  'remove': 'Supprimer',
  'noKeep': 'Non, conserver',
  'yesRemove': 'Oui, supprimer',
  'share': 'Partager',
  'getStarted': 'Commencer',
  'confirmRemove': 'Êtes-vous certain de vouloir supprimer ce document ?',
  'giftDetails.title': 'Montant du don',
  'giftDetails.banner.title': 'Combien voulez-vous offrir ?',
  'giftDetails.banner.description': "Choisissez le montant que vous souhaitez envoyer. Vous pouvez faire un don d’aussi peu que 25 $, jusqu’à concurrence de 2 500 $.",
  'giftDetails.amount.input.label': 'Quel est le montant de votre don ?',
  'giftDetails.amount.input.textFieldLabel': 'Sur mesure',
  'giftDetails.message.input.label': 'Ajouter un message (facultatif)',
  'giftDetails.message.input.placeholder': 'Un petit quelque chose pour vous. Bonne chance et succès!',
  'giftDetails.about.label': 'Envoyez-lui une note-cadeau',
  'giftDetails.about.description': 'Lisez-la en sachant que vous pensez à lui avec un message personnalisé.',
  'giftDetails.options.custom': 'Sur mesure',
  'giftDetails.error.amountEmpty': 'Veuillez choisir une option pour continuer.',
  'giftDetails.error.amountUnderMin': 'Inscrivez un minimum de 25 $.',
  'giftDetails.error.amountOverMax': 'Inscrivez un minimum de 2 500 $.',
  'giftDetails.error.exceededMaximumCharacter': 'La note-cadeau doit être de 1 000 caractères ou moins.',
  'giftDetails.error.allFieldsRequired': 'Veuillez fournir plus de détails pour continuer.',
  'giftCompliance.title': 'Nous avons besoin de quelques détails',
  'giftCompliance.banner.title': 'Votre relation',
  'giftCompliance.banner.description': 'Nous vous demandons ces renseignements afin de nous assurer que nous respectons les lignes directrices gouvernementales relatives aux régimes enregistrés d’épargne-études (REEE).',
  'giftCompliance.relationship.heading': 'Quelle est votre relation avec l’enfant',
  'giftCompliance.relationship.dropdown.label': 'Relation',
  'giftCompliance.relationship.dropdown.grandparent': 'Grand-parent',
  'giftCompliance.relationship.dropdown.aunt': 'Tante',
  'giftCompliance.relationship.dropdown.uncle': 'Oncle',
  'giftCompliance.relationship.dropdown.sibling': 'Frère ou sœur',
  'giftCompliance.relationship.dropdown.parent': 'Parent',
  'giftCompliance.relationship.dropdown.notRelated': 'Aucun lien de parenté',
  'giftCompliance.relationship.dropdown.other': 'Autre',
  'giftCompliance.error.noRelationship': 'Veuillez sélectionner la relation.',
  'giftError.title.expired': 'Quelque chose ne va pas',
  'giftError.description.expired': "Il semble que ce lien avec Embark soit expiré. Demandez au client de créer un nouveau lien.",
  'facebook': 'Facebook',
  'x': 'X',
  'whatsApp': 'WhatsApp',
  'reddit': 'Reddit',
  'linkedIn': 'LinkedIn',
  'img.alt.annulus': `Une forme d'anneau coupée en deux`,
  'img.alt.schoolPin': "Icône d'un capuchon de graduation",
  'img.alt.clippedSquarePattern': 'Petit carré',
  'img.alt.smallCirclePattern': 'Petit cercle',
  'img.alt.extraLargeCirclePattern': 'cercle très grand',
  'img.alt.professionsSearchRocketTelescope': `Illustration d'une personne bleue regardant à travers un télescope portant un jetpack`,
  'img.alt.largeCirclePattern': 'Grand cercle',
  'img.alt.halfCirclePattern': 'Demi cercle',
  'img.alt.diamondPattern' : 'Diamant',
  'img.alt.noSearchOptionsIllustration': `Illustration d'une antenne ne recevant aucun signal`,
  'img.alt.professionsSearchIllustration' : `Illustration d'une personne bleue naviguant sur Internet`,
  'img.alt.emailIcon': 'Email',
  'img.alt.searchIcon': 'Recherche',
  'img.alt.closeIcon': 'Fermer',
  'img.alt.arrowBackIcon': 'Flèche arrière',
  'img.alt.programScholarIcon': 'Icône de la loupe de fouille à travers du papier',
  'img.alt.sinDrivingLicense': 'Permis de conduire NAS',
  'img.alt.successIcon': 'Icône de réussite',
  'img.alt.apolloIcon': `Icône de l'entreprise Apollo`,
  'img.alt.youthfullyIcon': `Icône de l'entreprise Youthfully`,
  'img.alt.willfulIcon': `Icône de l'entreprise Willful`,
  'img.alt.borrowellIcon': `Icône de l'entreprise de Borrowell`,
  'img.alt.neoFinancialIcon': `Icône de l'entreprise de NeoFinancial`,
  'img.alt.policyMe': `Icône de l'entreprise de Assure Moi`,
  'img.alt.facebookIcon': `Icône de l'entreprise de Facebook`,
  'img.alt.xIcon': `Icône de l'entreprise de X`,
  'img.alt.whatsAppIcon': `Icône de l'entreprise de WhatsApp`,
  'img.alt.redditIcon': `Icône de l'entreprise de Reddit`,
  'img.alt.linkedInIcon': `Icône de l'entreprise de LinkedIn`,
  'img.alt.specialization': 'Notifier',
  'img.alt.btnPlusIcon': 'Icône Plus',
  'img.alt.btnTransferIcon': 'Icône de transfert',
  'img.alt.trendingUpIcon': 'Tendance à la hausse',
  'img.alt.iq.yellowArrow': 'flèche jaune sans queue',
  'img.alt.iq.thumbsUp': 'Bravo',
  'img.alt.iq.thumbsDown': 'Pouce vers le bas',
  'img.alt.giftLinkError': 'Erreur de liaison',
  'img.alt.giftCircle': 'Petite boîte actuelle',
  'gifting.about.headerOne': 'Vous avez été invité à faire un don au REEE de',
  'gifting.about.headerTwo': 'RESP',
  'gifting.about.bodyOne': 'Votre don au REEE de',
  'gifting.about.bodyTwo': `jouera un rôle crucial dans l'orientation de leur avenir. Il appuiera leurs aspirations en matière d'éducation et leur permettra de réaliser leurs rêves.`,
  'gifting.about.button': 'Cadeau maintenant',
  'gifting.list.header': 'À propos d’Embark Gifting',
  'gifting.list.one.header': 'Les cadeaux embarqués sont sécuritaires.',
  'gifting.list.one.body': ' Vous pouvez faire des cadeaux de façon sécuritaire et pratique en utilisant les cartes de crédit, Apple Pay et Google Pay. Tout est crypté et sécuritaire.',
  'gifting.list.two.header': 'Un cadeau qui ne s’oublie pas',
  'gifting.list.two.body': 'Vous pouvez faire un don d’au plus 2 500 $, qui peut donner droit à 20 % de plus en subventions gouvernementales. Votre don est grandement apprécié; toutefois, veuillez noter qu’il ne s’agit pas d’un don et qu’il n’est pas admissible aux reçus aux fins de l’impôt.',
  'gifting.list.three.header': 'Votre relation',
  'gifting.list.three.body': 'Nous devons vous poser des questions sur votre relation avec le destinataire du cadeau afin de vous conformer à la réglementation gouvernementale.',
  'gifting.about.terms.body': 'En envoyant un cadeau à un enfant d’Embark, vous consentez aux',
  'gifting.about.terms.link': 'conditions.',
  'gifting.about.terms.alt': 'redirect icon',
  'gifting.review.header': 'Passons rapidement en revue',
  'gifting.review.name': 'Récipiendaire du cadeau de Embark :',
  'gifting.review.amount': 'Montant du cadeau du REEE de Embark :',
  'gifting.review.processingFee': 'Frais de traitement :',
  'gifting.review.total': 'Total facturé :',
  'gifting.review.message': 'Message du cadeau:',
  'gifting.review.button': 'Payer et envoyer des cadeaux',
  'gifting.review.dialog.header': 'Frais de traitement',
  'gifting.review.dialog.body': `La banque qui émet la carte de paiement exige des frais pour le service de traitement de l'opération. Nous faisons de notre mieux pour qu'ils demeurent aussi bas que possible.`,
  'footer.rightsReserved': 'Tous droits réservés.',
  'footer.trademarkLine2': "Embark est une marque commerciale d'Embark Student Corp.",
  'networkError.title': "Quelque chose ne va pas. Veuillez réessayer plus tard.",
  'gifting.success.header': 'Votre don REEE Embark a été envoyé!',
  'gifting.success.body': 'Vous recevrez un reçu dans votre boîte de réception et nous aviserons le client d’Embark que votre cadeau est en route!',
  'gifting.success.button': 'Fait'
};
